import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../shared/Header";
import Popup from "../../shared/PopUp";
import "./validation.scss";
import { useLoading } from "../../auth/loader/loading.context";
import { validateUserDetails } from "../../API/api";
import {
  isEmptyInput,
  isEmptyObject,
  PopUpType,
} from "../../shared/Validation/inputValidation";

export function Validation() {
  const [loginError, setLoginUserError] = useState("");
  const { loading, setLoading } = useLoading();

  const [showError, setShowError] = useState<string>("");

  const popupRef = useRef<{ updateErrorMessage: (apiError: string) => void }>(
    null
  );

  const navigate = useNavigate();

  const validateUserData = async () => {
    setLoading(true);
    const data: any = await validateUserDetails(
      inputFields.firstname,
      inputFields.lastname,
      inputFields.dob
    );
    if (data.data.IsSuccess === true) {
      navigate("/preferredcommunication");
    } else {
      if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setShowError(
          "There was a problem validating your account. Please call support at 1-800-234-5678"
        );
      }
    }
    setLoading(false);
  };

  const [inputFields, setInputFields] = useState({
    firstname: "",
    lastname: "",
    dob: "",
  });
  const [errors, setErrors] = useState({
    lastname: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues: any) => {
    let errors = { lastname: "" };
    if (isEmptyInput(inputValues.lastname)) {
      errors.lastname = "Please enter Last Name";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  useEffect(() => {
    if (isEmptyObject(errors) && submitting) {
      validateUserData();
    }
  }, [errors]);

  return (
    <div>
      <Header></Header>
      <form onSubmit={handleSubmit}>
        <div className="flex w-full h-full justify-center items-center">
          <div className="p-6 w-full h-full max-w-sm md:h-full">
            <header className="mt-16 text-3xl font-semibold text-verydarkgrey">
              Account Validation
            </header>
            <p className="mb-5 flex w-full items-center info-style">
              Enter the following required information to validate your account.
            </p>
            <div className="flex flex-row">
              <p className="errorColor text-2xl font-nova-semibold mt-3">*</p>
              <p
                className={`flex w-full mt-3 ml-1 items-center label-style font-semibold ${
                  errors.lastname ? "errorColor" : "text-darkgrey"
                }`}
              >
                Last Name
              </p>
            </div>
            <div
              className={`w-full h-11 rounded-md px-4 input-style ${
                errors.lastname && "errorHightlight"
              }`}
            >
              <input
                type="lastname"
                name="lastname"
                value={inputFields.lastname}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              {errors.lastname && (
                <div className="flex relative my-2 error userMargin">
                  <span
                    style={{ paddingTop: "3px" }}
                    className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                  >
                    info
                  </span>
                  <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                    {errors.lastname}
                  </p>
                </div>
              )}
            </div>
            <p className="mt-2 flex w-full items-center info-style">
              Name will display as entered
            </p>
            {loginError.length > 0 && (
              <div className="flex mt-3">
                <span
                  style={{ paddingTop: "3px" }}
                  className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                >
                  info
                </span>
                <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                  Validation information is incorrect
                </p>
              </div>
            )}
            <button
              className="w-full h-11 mt-10 rounded-md bg-primary hover:bg-primary active:translate-y-[0.125rem] active:border-b-primary text-white font-semibold text-3xl"
              type="submit"
            >
              Validate
            </button>
            {showError && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Message}
                headerText="Validation Error"
                middleContent={showError}
                acceptButtonText="OK"
                acceptButtonEvent={() => {
                  setShowError("");
                }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
