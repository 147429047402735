export const resolve = async (promise: any) => {
    const resolved = {
      data: null,
      error: null,
    };
    try {
      resolved.data = await promise;
    } catch (e) {
      resolved.error = e as any;
    }
    return resolved;
  };