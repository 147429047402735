export function isEmptyObject(object: any) {
  return Object.keys(object).every(function (x) {
    return object[x] === "" || object[x] === null;
  });
}

export function isEmptyInput(inputValue: string) {
  if (inputValue.length === 0) {
    return true;
  }
  return false;
}

export function isValidEmail(inputValue: string) {
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRegex.test(inputValue)) {
    return true;
  }
  return false;
}

export function isValidAuthCode(inputValue: string) {
  const authCodeRegex = new RegExp("^.{6,6}$");
  if (authCodeRegex.test(inputValue)) {
    return true;
  }
  return false;
}

export function isValidMobileNumber(inputValue: string) {
  const mobileRegex =
    /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)|(\.|\s)?(\d{3})(\-|\s)|(\.|\s)?(\d{4})$/;
  if (mobileRegex.test(inputValue)) {
    const mustTenDigitRegex = new RegExp("^.{10,10}$");
    if (mustTenDigitRegex.test(inputValue.replace(/[^0-9 ]/g, ""))) {
      return true;
    }
    return false;
  }
  return false;
}

export function isValidUsername(inputValue: string) {
  const passwordRegex = new RegExp("^.{6,30}$");
  if (passwordRegex.test(inputValue)) {
    return true;
  }
  return false;
}

export function isValidPassword(inputValue: string, username: string) {
  const passwordRegex = new RegExp(
    "^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.{6,30})"
  );
  let isValidPassword = false;
  if (passwordRegex.test(inputValue)) {
    isValidPassword = true;
  }
  if (inputValue === "password") {
    isValidPassword = false;
  }
  if (inputValue === username) {
    isValidPassword = false;
  }
  return isValidPassword;
}

export enum PopUpType {
  Message = 1,
  Validation_Failed,
  Autheticate_Code,
  Send_TFA,
  Code_Expire,
}
