import axios from "axios";

import { resolve } from "./resolve";

interface DatasType {
  CustomerId: number;
  Username: string;
  UserType: string;
  ORGID: number;
  FirstName: string;
  LastName: string;
  Email: string;
  CanChangeLtPassword: true;
  JwtToken: string;
  Auth: string;
  StartPage: string;
}
const req = {
  API_SECRET_KEY: "EA12AFEC-8DBA-4AFE-88DA-EB52BF117FB7",
  API_VERSION: "1.0",
  CLIENT_VERSION: "",
  API_NAME: "ValidateUserDetails",
  API_AUTHENTICATION_TYPE: "auth_invitation_jwt",
  API_AUTHENTICATION_TOKEN: "",
};

export async function invitationlinkclicked() {
  req.API_NAME = "invitationlinkclicked";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {};
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function validateUserDetails(
  fisrtname: string,
  lastname: string,
  dob: string
) {
  req.API_NAME = "ValidateUserDetails";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {
    ValidationFields: {
      FirstName: fisrtname,
      LastName: lastname,
      dtDOB: dob,
    },
  };
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function getCommunicationAddressesFromAPI() {
  req.API_NAME = "GetCommunicationAddresses";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {};
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function setPreferredCommunication(
  type: string,
  value: string,
  consent: boolean
) {
  req.API_NAME = "SetPreferredCommunication";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {
    PreferredCommunication: type,
    SMSConsent: type === "SMS" ? consent : false,
    EmailId: type === "Email" ? value : "",
    Phone: type === "SMS" ? value : "",
  };
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function get2FACode() {
  req.API_NAME = "Send2FA";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function Validate2FACode(FACode: number) {
  req.API_NAME = "Validate2FACode";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {
    Code: FACode,
  };
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export async function createAccountWithDetails(
  username: string,
  password: string
) {
  req.API_NAME = "invitesetusernameandpassword";
  req.API_AUTHENTICATION_TOKEN = sessionStorage.token;
  const apiPayload = {
    Username: username,
    Password: password,
  };
  return await resolve(
    axios
      .post(`${process.env.REACT_APP_USER_ONBOARDING_BACKEND_URL}`, {
        API_PAYLOAD: apiPayload,
        ...req,
      })
      .then((res) => res.data)
  );
}

export const getSessionDetail = () => {
  return sessionStorage.token ? true : false;
};
