import React, { createContext, useContext, useState } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import "../../index.scss";
interface ILoading {
  loading: boolean;
  setLoading: any;
}

function Loading() {
  return <div className="loader-wrapper"><FadeLoader color="#0074BF"/></div>;
}

const LoadingContext = createContext<ILoading>({
  loading: false,
  setLoading: null,
});

export function LoadingProvider(props: any) {
  const [loading, setLoading] = useState(false);
  const value = { loading, setLoading };
  return (
    <LoadingContext.Provider value={value}>
      {loading && <Loading />}
      {props.children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within LoadingProvider");
  }
  return context;
}