import React, {
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import "./popup.scss";
import {
  isEmptyInput,
  isValidAuthCode,
  PopUpType,
} from "../Validation/inputValidation";

export interface PopupProps {
  popUpType: number;
  headerText: string;
  middleContent: string;
  declineButtonText?: string;
  declientButtonEvent?: () => void;
  acceptButtonText?: string;
  acceptButtonEvent?: () => void;
  resendButtonEvent?: (event: any) => void;
  sTFAButtonText?: string;
  sTFAButtonEvent?: (phoneNumber: any) => void;
}

const Popup = forwardRef(
  (
    {
      popUpType,
      headerText,
      middleContent,
      declineButtonText = "",
      declientButtonEvent,
      acceptButtonText = "",
      acceptButtonEvent,
      resendButtonEvent,
      sTFAButtonText = "",
      sTFAButtonEvent,
      ...props
    }: PopupProps,
    ref
  ) => {
    const [value, setNewValue] = useState("");
    const [error, setError] = useState("");

    function handleChange(event: any) {
      let value = event.target.value;
      setNewValue(value);
    }

    useImperativeHandle(ref, () => ({
      updateErrorMessage(apiError: string) {
        setError(apiError);
      },
    }));

    const onAuthenticateClick = () => {
      if (isEmptyInput(value) || !isValidAuthCode(value)) {
        setError("Please enter valid Six-Digit Code");
      } else {
        setError("");
        sTFAButtonEvent && sTFAButtonEvent(value);
      }
    };

    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative my-6 w-11/12 mx-auto md:w-96">
            {/*content  max-w-3xl*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
              {/*header*/}
              <div className="flex items-start justify-between border-b-0 rounded-t popup-header">
                <h3 className="text-primary text-4xl font-semibold">
                  {headerText}
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={declientButtonEvent}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              {popUpType === PopUpType.Message && (
                <div className="relative flex-auto popup-middle-content">
                  <p className="text-2xl leading-normal font-medium">
                    {middleContent.includes("1-800-234-5678")
                      ? middleContent.replace("1-800-234-5678", "")
                      : middleContent}
                    <p className="text-primary underline">
                      <a href="tel:1-800-234-5678">
                        {middleContent.includes("1-800-234-5678")
                          ? "1-800-234-5678"
                          : ""}
                      </a>
                    </p>
                  </p>
                </div>
              )}
              {popUpType === PopUpType.Send_TFA && (
                <div className="relative flex-auto popup-middle-content">
                  <p className="text-xl leading-normal font-nova-medium">
                    Your two-factor authentication code will be sent to your
                    preferred method of contact shown below.
                    <p className="text-2xl mt-2 mb-4 leading-normal font-nova-semibold text-verydarkgrey">
                      {middleContent}
                    </p>
                    The two-factor authentication code will expire after 15
                    minutes.
                  </p>
                </div>
              )}
              {popUpType === PopUpType.Autheticate_Code && (
                <div className="relative flex-auto popup-middle-content">
                  <p className="text-xl leading-normal font-nova-medium">
                    A six-digit code was sent to your preferred method of
                    contact. Enter the code to continue.
                    <div className="mt-5">
                      <div className="flex justify-between">
                        <p
                          className={`text-xl font-semibold text-dimGray ${
                            error ? "errorColor" : "ttext-dimGray"
                          }`}
                        >
                          Six-Digit Code
                        </p>
                        <div className="text-2xl font-semibold text-blue">
                          <button
                            className="bg-transparent border-0 text-blue text-2xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={resendButtonEvent}
                          >
                            Resend Code
                          </button>
                        </div>
                      </div>
                      <div
                        className={`h-11 rounded-md px-4 input-style ${
                          error && "errorHightlight"
                        }`}
                      >
                        <input
                          type="number"
                          value={value}
                          onChange={handleChange}
                          className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
                        />
                        {error && (
                          <div className="flex relative mt-2 error userMargin">
                            <span
                              style={{ paddingTop: "3px" }}
                              className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                            >
                              info
                            </span>
                            <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                              {error}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </p>
                </div>
              )}
              <div className="flex items-center justify-end p-6 border-t-0 rounded-b">
                {declineButtonText !== "" && (
                  <button
                    className="w-1/3 h-9 text-primary background-transparent font-semibold border-none text-3xl ease-linear transition-all duration-150 popup-decline-button "
                    type="button"
                    onClick={declientButtonEvent}
                  >
                    {declineButtonText}
                  </button>
                )}
                {acceptButtonText !== "" && (
                  <button
                    className="w-1/2 h-9 rounded-md bg-primary font-semibold text-white popup-accept-button hover:bg-primary active:translate-y-[0.125rem] active:border-b-primary text-3xl popup-accept-button"
                    type="button"
                    onClick={acceptButtonEvent}
                  >
                    {acceptButtonText}
                  </button>
                )}
                {sTFAButtonText !== "" && (
                  <button
                    className="w-1/2 h-9 rounded-md bg-primary font-semibold text-white popup-accept-button hover:bg-primary active:translate-y-[0.125rem] active:border-b-primary text-3xl popup-accept-button"
                    type="button"
                    onClick={onAuthenticateClick}
                  >
                    {sTFAButtonText}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }
);

export default Popup;
