import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { Header } from "../../shared/Header";
import "./inviteVerification.scss";
import { useLoading } from "../../auth/loader/loading.context";
import { getSessionDetail, invitationlinkclicked } from "../../API/api";

export function InviteVerification() {
  const { loading, setLoading } = useLoading();
  const navigate = useNavigate();

  const [tokenExpired, setTokenExpired] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  if (searchParams.get("token") !== null) {
    sessionStorage.setItem("token", searchParams.get("token")!);
    const decoded = jwtDecode(sessionStorage.token);
    sessionStorage.setItem("user", JSON.stringify(decoded));
  }

  useEffect(() => {
    setTokenExpired(false);
    if (!getSessionDetail()) {
      setTokenExpired(true);
    } else {
      postInvitationLinkClicked();
    }
  }, []);

  const postInvitationLinkClicked = async () => {
    setLoading(true);
    const data: any = await invitationlinkclicked();
    if (data.data.IsSuccess === true) {
      navigate("/validate");
    } else {
      setTokenExpired(true);
    }
    setLoading(false);
  };

  return (
    <div>
      <Header></Header>
      <div className={tokenExpired ? undefined : "hidden"}>
        <div className="flex w-full h-full justify-center items-center">
          <div className="p-6 w-full h-full max-w-sm md:h-full">
            <p className="w-full items-center info-style">
              Your invitation link has expired.
              <p>
                Please contact your provider for a new one to complete your
                account setup.
              </p>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
