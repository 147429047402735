import React from "react";
import LOGO from "../../assets/images/logo.png";
import DULA_LOGO from "../../assets/images/dula-logo.png";
import CORE_LIFE_LOGO from "../../assets/images/CL-logo.png";
import "./header.scss";

export function Header() {
  const userDetails = JSON.parse(sessionStorage.user);
  var Which_Logo = LOGO;
  if (userDetails.ParentOrgId === "121" || userDetails.OrgId === "121") {
    Which_Logo = DULA_LOGO;
  } else if (userDetails.ParentOrgId === "118" || userDetails.OrgId === "118") {
    Which_Logo = CORE_LIFE_LOGO;
  }

  return (
    <div className="w-full h-full bg-white shadow-md shadow-lightgrey fixedElement">
      <div className="grid grid-cols-3 gap-4">
        <div className="max-sm:col-start-2 max-xl:col-start-1">
          <div className="flex xl:ml-5 max-h-[140px] md:max-h-[160px] lg:max-h-[200px] max-xl:justify-start max-xl:justify-items-start max-sm:justify-center max-sm:justify-items-center">
            <img
              src={Which_Logo}
              className="p-1 object-contain logoWidth"
              alt="LOGO"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}
