import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../shared/Header";
import Popup from "../../shared/PopUp";
import "./preferredCommunication.scss";
import { useLoading } from "../../auth/loader/loading.context";
import {
  Validate2FACode,
  get2FACode,
  getCommunicationAddressesFromAPI,
  setPreferredCommunication,
} from "../../API/api";
import {
  isEmptyInput,
  isEmptyObject,
  isValidEmail,
  isValidMobileNumber,
  PopUpType,
} from "../../shared/Validation/inputValidation";

type communicationDetails = {
  CustomerId: number;
  EmailAddress: string;
  PhoneNumber: string;
  SMSConsent: boolean;
  PreferredCommunication: string;
};

export function PreferredCommunication() {
  const navigate = useNavigate();
  const [isFirstTimeLoading, setFirstTimeLoading] = useState(true);
  const { loading, setLoading } = useLoading();
  const [popUpType, setPopUpType] = useState(0);
  const [popUpMiddleContent, setPopUpMiddleContent] = useState("");
  const [isConsentAccepted, setConsentAccepted] = useState(false);

  const popupRef = useRef<{
    updateErrorMessage: (apiError: string) => void;
  }>(null);

  const [communicationDetailsData, setcommunicationDetailsData] =
    useState<communicationDetails | null>(null);

  const [authType, setAuthType] = useState("Email");

  const [errors, setErrors] = useState({ email: "", sms: "", consent: "" });

  const [inputFields, setInputFields] = useState({
    email: "",
    sms: "",
  });
  const [submitting, setSubmitting] = useState(false);

  const validateValues = (inputValues: any) => {
    let errors = { email: "", sms: "", consent: "" };
    if (isEmptyInput(inputValues.email) && authType === "Email") {
      errors.email = "Please enter an email";
    } else if (!isValidEmail(inputValues.email) && authType === "Email") {
      errors.email = "Please enter a valid email";
    }
    if (isEmptyInput(inputValues.sms) && authType === "SMS") {
      errors.sms = "Please enter a phone number";
    } else if (!isValidMobileNumber(inputValues.sms) && authType === "SMS") {
      errors.sms = "Please enter a valid phone number";
    }
    if (
      !isEmptyInput(inputValues.sms) &&
      isConsentAccepted === false &&
      authType === "SMS"
    ) {
      errors.consent = "You must consent to receive SMS (text) messages";
    }
    return errors;
  };

  const handleChange = (e: any) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  useEffect(() => {
    if (isEmptyObject(errors) && submitting) {
      setPreferredMethodOfCommunication();
    }
  }, [errors]);

  useEffect(() => {
    if (isFirstTimeLoading) {
      setFirstTimeLoading(false);
      getCommunicationAddresses();
    }
  }, []);

  useEffect(() => {
    if (communicationDetailsData) {
      setInputFields({
        email: communicationDetailsData?.EmailAddress!,
        sms: communicationDetailsData?.PhoneNumber!,
      });
      setAuthType(communicationDetailsData?.PreferredCommunication!);
      setConsentAccepted(communicationDetailsData?.SMSConsent!);
    }
  }, [communicationDetailsData]);

  const getCommunicationAddresses = async () => {
    setLoading(true);
    const data: any = await getCommunicationAddressesFromAPI();
    if (data.data.IsSuccess === true) {
      setcommunicationDetailsData(data.data.Data);
      console.log(communicationDetailsData?.EmailAddress);
    } else {
      if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setPopUpMiddleContent(data.data.Data.Error_Message);
        setPopUpType(PopUpType.Message);
      }
    }
    setLoading(false);
  };

  const setPreferredMethodOfCommunication = async () => {
    setLoading(true);
    const data: any = await setPreferredCommunication(
      authType,
      authType === "Email"
        ? inputFields.email
        : inputFields.sms.replace(/[^0-9 ]/g, ""),
      authType === "SMS" ? isConsentAccepted : false
    );
    if (data.data.IsSuccess === true) {
      setPopUpMiddleContent(
        authType === "Email"
          ? `Email: ${inputFields.email}`
          : `SMS (Text): ${inputFields.sms.replace(/[^0-9 ]/g, "")}`
      );
      setPopUpType(PopUpType.Send_TFA);
    } else {
      if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setPopUpMiddleContent(data.data.Data.Error_Message);
        setPopUpType(PopUpType.Message);
      }
    }
    setLoading(false);
  };

  const get2FAuthenticationCode = async () => {
    setLoading(true);
    const data: any = await get2FACode();
    if (data.data.IsSuccess === true) {
      setPopUpType(PopUpType.Autheticate_Code);
    } else {
      if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setPopUpMiddleContent(data.data.Data.Error_Message);
        setPopUpType(PopUpType.Message);
      }
    }
    setLoading(false);
  };

  const doAuthenticationOf2FCode = async (authCode: string) => {
    setLoading(true);
    const data: any = await Validate2FACode(Number(authCode));
    if (data.data.IsSuccess === true) {
      navigate("/createaccount");
    } else {
      if (data.data.Data.Error_Message === "Expired Code") {
        setPopUpType(0);
        setPopUpMiddleContent(
          `Select 'Send' to send another authentication code to complete the two-factor authentication.`
        );
        setPopUpType(PopUpType.Code_Expire);
      } else if (data.data.Data.Error_Message === "Incorrect Code") {
        if (popupRef && popupRef.current)
          popupRef.current.updateErrorMessage("Invalid code, please try again");
      } else if (
        data.data.Data.Error_Type === "ACCESS_ERROR" &&
        data.data.Data.Error === "Invalid_Authentication_Token"
      ) {
        navigate("/verify");
      } else {
        setPopUpType(0);
        setPopUpMiddleContent(data.data.Data.Error_Message);
        setPopUpType(PopUpType.Message);
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <Header></Header>
      <form onSubmit={handleSubmit}>
        <div className="flex w-full h-full justify-center items-center">
          <div className="p-6 w-full h-full max-w-sm md:h-full">
            <header className="px-10 mt-16 text-3xl leading-tight font-semibold text-verydarkgrey">
              Preferred Method of Communication
            </header>
            <p className="px-5 mt-2 mb-5 flex w-full items-center info-style">
              Enter one required preferred method of contact.
            </p>
            <div className="radio-container text-left">
              <label
                className={`ml-2 text-xl font-semibold ${
                  errors.email ? "errorColor" : "text-darkgrey"
                }`}
              >
                Email
                <input
                  key="email"
                  value="Email"
                  type="radio"
                  name="authenticate_Type"
                  onChange={(e) => {
                    setAuthType("Email");
                    if (isConsentAccepted) {
                      setConsentAccepted(!isConsentAccepted);
                    }
                    setSubmitting(false);
                    setErrors({ email: "", sms: "", consent: "" });
                  }}
                  checked={authType === "Email"}
                />
                <span className="radiomark cursor-pointer"></span>
              </label>
            </div>
            <div
              className={`ml-8 h-11 rounded-md px-4 input-style ${
                errors.email && "errorHightlight"
              }`}
            >
              <input
                type="text"
                name="email"
                value={inputFields.email}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              {errors.email && (
                <div className="flex relative mt-2 error userMargin">
                  <span
                    style={{ paddingTop: "3px" }}
                    className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                  >
                    info
                  </span>
                  <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                    {errors.email}
                  </p>
                </div>
              )}
            </div>
            <p className="flex w-full"></p>
            <div className="radio-container mt-3 text-left">
              <label
                className={`ml-2 text-xl font-semibold ${
                  errors.sms ? "errorColor" : "text-darkgrey"
                }`}
              >
                SMS (Text)
                <input
                  key="sms"
                  value="SMS"
                  type="radio"
                  name="authenticate_Type"
                  onChange={(e) => {
                    setAuthType("SMS");
                    setSubmitting(false);
                    setErrors({ email: "", sms: "", consent: "" });
                  }}
                  checked={authType === "SMS"}
                />
                <span className="radiomark cursor-pointer"></span>
              </label>
            </div>
            <div
              className={`ml-8 h-11 rounded-md px-4 input-style ${
                errors.sms && "errorHightlight"
              }`}
            >
              <input
                name="sms"
                value={inputFields.sms}
                onChange={handleChange}
                className="fos:outline-none my-2 w-full border-none bg-transparent outline-none"
              />
              {errors.sms && (
                <div className="flex relative mt-2 error userMargin">
                  <span
                    style={{ paddingTop: "3px" }}
                    className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                  >
                    info
                  </span>
                  <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                    {errors.sms}
                  </p>
                </div>
              )}
            </div>
            <p className="flex w-full"></p>
            <ul className="mt-2 px-10 w-full list-disc username-passowrd-tip">
              <li>Must be a 10 digit North American phone number</li>
              <li>No country code required (eg. +1)</li>
            </ul>
            <p className="flex w-full"></p>
            <div className="checkbox-container ml-8 mt-3 text-left">
              <label className=" label-style font-semibold">
                Consent to receive SMS (text) messages
                <input
                  type="checkbox"
                  checked={isConsentAccepted}
                  onChange={(e) => {
                    setConsentAccepted(!isConsentAccepted);
                    setSubmitting(false);
                    setErrors({
                      email: errors.email,
                      sms: errors.sms,
                      consent: "",
                    });
                  }}
                />
                <span
                  className={`checkmark cursor-pointer ${
                    errors.consent ? "checkmark-validation-error" : "none"
                  }`}
                ></span>
              </label>
            </div>
            <p className="ml-16 text-left text-primary underline text-xl font-semibold">
              <a
                href="https://www.mozzaz.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Consent Policy
              </a>
            </p>
            {errors.consent && (
              <div className="flex ml-8 mt-3">
                <span
                  style={{ paddingTop: "3px" }}
                  className="material-icons-outlined  text-2xl text-form-input-required pr-0.5"
                >
                  info
                </span>
                <p className="text-left ml-2 text-xl text-form-input-required font-nova-medium">
                  You must consent to receive SMS (text) messages
                </p>
              </div>
            )}
            <button
              className="w-full h-11 mt-10 rounded-md bg-primary hover:bg-primary active:translate-y-[0.125rem] active:border-b-primary text-white font-semibold text-3xl"
              type="submit"
            >
              Authenticate
            </button>
            {popUpType === PopUpType.Send_TFA && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Send_TFA}
                headerText="Two-Factor Authentication"
                middleContent={popUpMiddleContent}
                acceptButtonText="Send"
                declineButtonText="Cancel"
                acceptButtonEvent={() => {
                  setPopUpType(0);
                  get2FAuthenticationCode();
                }}
                declientButtonEvent={() => {
                  setPopUpType(0);
                }}
              />
            )}
            {popUpType === PopUpType.Autheticate_Code && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Autheticate_Code}
                headerText="Two-Factor Authentication"
                middleContent=""
                sTFAButtonText="Authenticate"
                declineButtonText="Cancel"
                resendButtonEvent={(event) => {
                  event.preventDefault();
                  if (popupRef && popupRef.current)
                    popupRef.current.updateErrorMessage("");
                  get2FAuthenticationCode();
                }}
                declientButtonEvent={() => {
                  setPopUpType(0);
                }}
                sTFAButtonEvent={(authCode) => {
                  doAuthenticationOf2FCode(authCode);
                }}
              />
            )}
            {popUpType === PopUpType.Code_Expire && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Message}
                headerText="Authentication Code Expired"
                middleContent={popUpMiddleContent}
                acceptButtonText="Send"
                declineButtonText="Cancel"
                acceptButtonEvent={() => {
                  setPopUpType(0);
                  get2FAuthenticationCode();
                }}
                declientButtonEvent={() => {
                  setPopUpType(0);
                }}
              />
            )}
            {popUpType === PopUpType.Message && (
              <Popup
                ref={popupRef}
                popUpType={PopUpType.Message}
                headerText="Error"
                middleContent={popUpMiddleContent}
                acceptButtonText="OK"
                acceptButtonEvent={() => {
                  setPopUpType(0);
                }}
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
